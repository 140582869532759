<template>
  <el-form :model="editForm" :rules="rules" ref="editForm">
    <el-row :gutter="20">
      <el-col :span="12" v-for="(i,k) in editForm" :key="k">
        <el-form-item :label="$t(k)" :prop="k">
          <el-input size="medium" v-model="editForm[k]"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  name: 'myForm',
  props: ['editForm'],
  computed: {
    rules () {
      let v = {};
      for (let i in this.editForm) {
        v[i] = [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }]
      }
      return v;
    }
  }
}
</script>

<style>
</style>