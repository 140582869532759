<template>
  <div>
    <!-- <print-credentials :param="param" />
    <my-form :editForm="param" /> -->
  </div>
</template>
<script>
import printCredentials from '@/components/printCredentials'
import myForm from '@/components/myForm'


export default {
  components: { printCredentials, myForm, upload },
  data () {
    return {
      param: {
        "姓名": "张三",
        "卡号": "00000012356",
        "交易单号": "00000000001",
        "交易时间": this.$manba().format('ymdhms'),
        "交易类型": "现金存款",
        "交易金额": "50000000",
        "手续费": "5000",
        "卡余额": "49995000",
        "经办人工号": "1000021",
        "操作机构": "隔壁泰山",
      },
    }
  },
  methods: {
  },
  mounted () { }
}
</script>

<style>
</style>